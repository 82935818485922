import React from 'react'
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'

const VideoWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 99999;
  background: rgba(255, 255, 255, 1);

  @media (prefers-color-scheme: dark) {
    background: rgba(0, 0, 0, 1);
  }
  @keyframes modalFade {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  animation-name: modalFade;
  animation-duration: 0.3s;
`

const Video = styled.video`
  width: auto;
  max-height: 80%;
  max-width: 90%;
`
const CloseButton = styled.button`
  position: fixed;
  right: 5vw;
  top: 5vh;
  border: none;
  background-color: transparent;
  color: #6b6c6f;
  cursor: pointer;
`

const videoModal = ({ video, closeModal }) => {
  return (
    <VideoWrapper>
      <Video controls autoPlay src={video} type="video/mp4" preload="auto" />
      <CloseButton onClick={closeModal}>
        <FiX size={48} />
      </CloseButton>
    </VideoWrapper>
  )
}

export default videoModal
