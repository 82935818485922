import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import format from 'date-fns/format'
import { FiMail, FiMapPin, FiPhoneCall, FiPlayCircle } from 'react-icons/fi'
import VideoModal from '../components/videoModal'
import Fade from 'react-reveal/Fade'

const AboutWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 540px) {
    padding: 0 20px;
  }
`

const About = styled.div`
  display: grid;
  grid-template-columns: 250px minmax(350px, 650px);
  grid-template-rows: 1fr auto;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  padding: 0 0 25px;
  overflow: hidden;
  grid-template-areas:
    'headshot content'
    'showreel content';

  .content {
    grid-area: content;

    @media screen and (min-width: 768px) {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .headshot {
    grid-area: headshot;
    margin-left: -60px;
    pointer-events: none;
  }

  .showreel {
    grid-area: showreel;
    position: relative;
    overflow: hidden;
    display: block;
    cursor: pointer;

    span {
      position: absolute;
      width: 100%;
      left: 20px;
      bottom: 20px;
      color: white;
      line-height: 22px;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: underline;
      text-shadow: rgba(0, 0, 0, 0.0980392) 1px 1px 1px;
      cursor: pointer;
      svg {
        margin-left: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 250px minmax(100px, 190px);
    grid-template-rows: 1fr 140px;
    /* grid-column-gap: 10px; */

    grid-template-areas:
      'content content'
      'showreel .';

    .headshot {
      display: none;
    }
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 140px;

    grid-template-areas:
      'content'
      'showreel';

    .headshot {
      display: none;
    }
  }
`

const Contact = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 50px;
  padding: 85px 0;
  line-height: 30px;

  @media screen and (max-width: 940px) {
    padding: 60px 0;
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding: 40px 0;
  }

  .contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 940px) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
    a {
      text-decoration: none;
      transition: linear 0.1s;
      :hover {
        border-bottom: 1px solid;
        border-color: inherit;
      }
    }

    .contact-info--icon-name {
      text-transform: uppercase;
      line-height: 18px;
      font-size: 12px;
      letter-spacing: 0.05em;
      font-weight: 600;
      color: hsl(0, 0%, 50%);
      padding: 2px 0;
      @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
      }
    }
    .contact-info--data {
      line-height: 24px;
      font-size: 16px;
      color: hsl(0, 0%, 20%);

      @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
      }
    }
  }
`

const AboutFooter = styled.div`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 20px;
    padding: 20px 0;

    svg {
      color: hsl(0, 0%, 60%);
      @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 40%);
      }
    }

    li {
      color: hsl(0, 0%, 40%);
      font-weight: 400;
      font-size: 14px;
      a {
        font-weight: 600;
        color: hsl(0, 0%, 40%);
        /* color: hsl(54, 3%, 11%); */
        text-decoration: none;
        transition: linear 0.1s;
        line-height: 24px;

        @media (prefers-color-scheme: dark) {
          color: hsl(0, 0%, 60%);
        }
        :hover {
          border-bottom: 1px solid;
          border-color: inherit;
          @media (prefers-color-scheme: dark) {
            border-color: white;
          }
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`

class AboutPage extends Component {
  state = {
    modalOpen: false,
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }))
  }

  render() {
    const { data } = this.props
    const about = data.contentfulAboutPage
    const video = data.contentfulAboutPage.video.file.url
    const date = format(new Date(), 'yyyy')
    return (
      <>
        {this.state.modalOpen && (
          <VideoModal video={video} closeModal={this.toggleModal} />
        )}
        <SEO title="About" />
        <AboutWrapper>
          <About>
            <Img className="headshot" fluid={about.image.fluid} />
            <a className="showreel" onClick={this.toggleModal}>
              <Img fluid={about.videoPoster.fluid} />
              <span>
                Watch my showreel
                <FiPlayCircle size={20} />
              </span>
            </a>

            <div className="content">
              <h1>About Paul</h1>
              <Fade bottom distance="25px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: about.shortbio.childMarkdownRemark.html,
                  }}
                />
              </Fade>
            </div>
          </About>
          <div className="divider" />
          <Contact>
            <h1>Contact</h1>
            <Fade bottom cascade distance="50px">
              <div className="contact-info">
                <ul>
                  <FiMail size={24} color={'hsl(0, 0%, 60%)'} />
                  <li className="contact-info--icon-name">Email</li>
                  <a
                    className="contact-info--data"
                    href={`mailto:${about.email}`}
                  >
                    {about.email}
                  </a>
                </ul>
                <ul>
                  <FiPhoneCall size={24} color={'hsl(0, 0%, 60%)'} />
                  <li className="contact-info--icon-name">Telefoon</li>
                  <a className="contact-info--data" href={`tel:${about.phone}`}>
                    {about.phone}
                  </a>
                </ul>
                <ul>
                  <FiMapPin size={24} color={'hsl(0, 0%, 60%)'} />
                  <li className="contact-info--icon-name">Adres</li>
                  <li className="contact-info--data">{about.street}</li>
                  <li className="contact-info--data">
                    {about.zipcode} {about.city}
                  </li>
                </ul>
              </div>
            </Fade>
          </Contact>
          <div className="divider" />
          <AboutFooter>
            <ul>
              <li>© {date} Paul Rietveld</li>
              {/* <Logo name="logo-paraaf" width="24px" height="24px" /> */}
              <li>
                Designed & built by{' '}
                <a href="http://absolutecode.nl">Absolute Code</a>
              </li>
            </ul>
          </AboutFooter>
        </AboutWrapper>
      </>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    contentfulAboutPage {
      id
      slug
      email
      phone
      name
      street
      zipcode
      city
      shortbio {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      video {
        file {
          url
          fileName
          contentType
        }
      }
      videoPoster {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
